<template>
  <div class="edit_roster">
    <asom-card>
      <asom-alert
        class="mb-4"
        v-if="error"
        variant="error"
        :error-message="error"
      >
      </asom-alert>
      <div v-if="isLoading" class="w-full text-center">
        <asom-icon icon="spinner" class="animate-spin" />
      </div>
      <form v-else class="grid sm:grid-cols-1 md:grid-cols-2 md:gap-x-6 gap-4">
        <asom-form-field
          label="Officer"
          :state="inputStates('formData.officer')"
        >
          <asom-input-select
            v-model="formData.officer"
            disabled
            :options="[]"
            :state="inputStates('formData.officer')"
          />
        </asom-form-field>
        <div class="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
          <asom-form-field
            label="MRT Line"
            required
            :state="inputStates('formData.lineId')"
          >
            <asom-input-select
              disabled
              :objectModelValue="false"
              v-model="formData.lineId"
              :options="lineOptions"
              :state="inputStates('formData.lineId')"
            />
          </asom-form-field>
          <asom-form-field
            label="MRT Station"
            required
            :state="inputStates('formData.stationId')"
          >
            <asom-input-select
              disabled
              :objectModelValue="false"
              v-model="formData.stationId"
              :options="stationOptions"
              :state="inputStates('formData.stationId')"
            />
          </asom-form-field>
        </div>
        <asom-form-field
          label="DWS"
          required
          :state="inputStates('formData.dws')"
        >
          <asom-input-select
            disabled
            v-model="formData.dws"
            :options="dwsOptions"
            track-by="dws"
            :state="inputStates('formData.dws')"
          />
        </asom-form-field>
        <div></div>
        <div class="grid sm:grid-cols-1 md:grid-cols-2 gap-4" v-if="!isOffRest">
          <asom-form-field
            label="Starting Time"
            :state="inputStates('formData.planStart')"
          >
            <asom-input-date-time
              disabled
              v-model="formData.planStart"
              :state="inputStates('formData.planStart')"
            />
          </asom-form-field>
          <asom-form-field
            label="Ending Time"
            :state="inputStates('formData.planEnd')"
          >
            <asom-input-date-time
              disabled
              v-model="formData.planEnd"
              :state="inputStates('formData.planEnd')"
            />
          </asom-form-field>
        </div>
        <asom-form-field
          v-if="!isOffRest"
          label="Time Duration"
          :state="inputStates('formData.planHours')"
          disabled
        >
          <asom-input-text
            type="text"
            v-model="numberOfHours"
            :state="inputStates('formData.planHours')"
            :disabled="true"
          />
        </asom-form-field>
        <section class="col-span-2 space-y-4">
          <hr />
          <div class="flex justify-end">
            <asom-button icon="math-plus" text="Add OT" @click="addOtDuration"></asom-button>
          </div>
          <asom-alert v-if="!formData.otDurations" title="No OT Duration Added">
          </asom-alert>
          <template v-else>
            <asom-card 
              v-for="(otDuration, index) in formData.otDurations"
              :key="index"
              dismissible
              @dismiss="removeOtDuration(index)"
            >
              <div class="grid md:grid-cols-2 gap-4">
                <asom-form-field
                  label="OT Starting Time"
                  required
                  :state="inputStates(`formData.otDuration.` + index + `.startTime`)"
                >
                  <asom-input-date-time
                    v-model="otDuration.startTime"
                    :state="inputStates(`formData.otDuration.` + index + `.startTime`)"
                  />
                </asom-form-field>
                <asom-form-field
                  label="OT Ending Time"
                  required
                  :state="inputStates(`formData.otDuration.` + index + `.endTime`)"
                >
                  <asom-input-date-time
                    v-model="otDuration.endTime"
                  :state="inputStates(`formData.otDuration.` + index + `.endTime`)"
                  />
                </asom-form-field>
                <asom-form-field
                  v-if="index === 0"
                  label="Break Duration"
                  :state="inputStates(`formData.otDuration.` + index + `.breakDuration`)"
                >
                  <asom-input-time
                    v-model="otDuration.breakDuration"
                  :state="inputStates(`formData.otDuration.` + index + `.breakDuration`)"
                  />
                </asom-form-field>
              </div>
            </asom-card>
          </template>
        </section>
        <asom-form-field
          required
          label="Remarks"
          :state="inputStates('formData.remarks')"
        >
          <asom-input-textarea
            type="text"
            v-model="formData.remarks"
            :state="inputStates('formData.remarks')"
            :maxlength="1000"
          />
        </asom-form-field>
      </form>
      <template #footer>
        <asom-button
          text="Cancel"
          variant="secondary"
          @click="$router.go(-1)"
        />
        <asom-button
          text="Submit"
          @click="submit(false)"
          :loading="isSubmitting"
        />
      </template>
    </asom-card>
    <asom-modal
      title-icon="error"
      title="Confirmation"
      v-model="showMaxOTHourModal"
      :dismissible="false"
    >
      <p>You are exceeding the max workable OT hours by MOM rule</p>
      <p>Do you want to continue?</p>
      <br />
      <div class="flex flex-row-reverse pt-4 gap-4">
        <asom-button
          @click="submit(true)"
          :disabled="isSubmitting"
          :loading="isSubmitting"
          text="Confirm"
        />
        <asom-button
          @click="showMaxOTHourModal = false"
          text="Cancel"
          variant="secondary"
        />
      </div>
    </asom-modal>
  </div>
</template>

<script>
import get from "lodash.get";
import moment from "moment";
import { mapGetters } from "vuex";
import { required } from "@vuelidate/validators";
import {
  getLineDws,
  getRosterDetails,
  postPlannedOT,
} from "@/services/manpower.service";
import { parseDateTime, displayDateTime } from "@/helpers/dateTimeHelpers";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import AsomFormField from "../../components/form_display/AsomFormField.vue";

export default {
  components: { AsomFormField },
  mixins: [inputStates],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      formData: {
        rosterId: this.$route.params.rosterId,
        officer: null,
        lineId: null,
        stationId: null,
        dws: null,
        planDate: null,
        planStart: null,
        planEnd: null,
        planHours: 0,
        otDurations: [],
        remarks: null,
      },
      showMaxOTHourModal: false,
      dwsOptions: [],
      isLoading: false,
      error: null,
      isSubmitting: false,
      numberOfHours: "",
      numberOfOTHours: "",
    };
  },
  validations() {
    return {
      formData: {
        rosterId: { required },
        officer: { required },
        lineId: { required },
        stationId: { required },
        dws: { required },
        planDate: { required },
        otDurations: {
          $each: {
            start: { required },
            endTime: { required },
            breakTime: { },
          }
        },
        remarks: { required },
      },
    };
  },
  computed: {
    ...mapGetters({
      lineOptions: "smrtResource/lineOptions",
      canSelectLine: "auth/canSelectLine",
    }),
    stationOptions() {
      return this.$store.getters["smrtResource/stationOptionsByLineId"](
        this.formData.lineId
      );
    },
    isOffRest() {
      return (
        this.formData.dws && ["OFF", "REST"].includes(this.formData.dws.dws)
      );
    },
    isValidOTTimeRange() {
      return this.formData.otDurations.every(({ startTime, endTime, breakDuration }) => {
        const OTStart = moment(startTime);
        const OTEnd = moment(endTime);
        const breakM = moment(breakDuration);
        return OTStart.isValid() && OTEnd.isValid() && breakM.isValid() &&
          OTStart.isBefore(OTEnd);
        
      });
    },
  },
  mounted() {
    this.getRoster();
  },
  watch: {
    "formData.planStart": function (newValue) {
      if (moment(newValue).isValid()) {
        this.setHours();
      }
    },
    "formData.planEnd": function (newValue) {
      if (moment(newValue).isValid()) {
        this.setHours();
      }
    },
  },
  methods: {
    getDwsLabel(dws, shiftStart, shiftEnd) {
      return (
        dws +
        (shiftStart && shiftEnd
          ? " (" + shiftStart + " - " + shiftEnd + ")"
          : "")
      );
    },
    setFormData({
      officerId,
      officer,
      line,
      station,
      planDate,
      planStart,
      planEnd,
      otDurations,
      dws,
      dwsText,
    }) {
      if (officerId) {
        this.formData.officer = {
          label: officer,
          value: officerId,
        };
        this.formData.lineId = get(line, "lineId");
        this.formData.stationId = get(station, "stationId");
        this.formData.dws = {
          label: this.getDwsLabel(
            dws,
            planStart ? planStart.split(" ")[1] : null,
            planEnd ? planEnd.split(" ")[1] : null
          ),
          dwsText: dwsText,
          dws: dws,
        };
        this.formData.planDate = planDate;
        this.formData.otDurations = otDurations.map((o) => ({
          startTime: moment(o.startTime).toDate(),
          endTime: moment(o.endTime).toDate(),
          breakDuration: moment(o.breakDuration, 'HH:mm:ss').toDate(),
        }))
        if (planStart)
          this.formData.planStart = moment(
            planStart,
            "DD/MM/YYYY HH:mm:ss"
          ).toDate();
        if (planEnd) {
          this.formData.planEnd = moment(
            planEnd,
            "DD/MM/YYYY HH:mm:ss"
          ).toDate();
        }
        if (
          moment(this.formData.planStart).isValid() &&
          moment(this.formData.planEnd).isValid()
        ) {
          this.setHours();
        }
      }
    },
    setHours() {
      let start = moment(this.formData.planStart);
      let end = moment(this.formData.planEnd);
      this.formData.planHours = end.diff(start, " Hours", true);
      let hoursString = end.diff(start, "hours");
      let minutesString = end.diff(start, "minutes") - hoursString * 60;
      let hourText = hoursString > 1 ? " Hours " : " Hour ";
      let minuteText = minutesString > 1 ? " Minutes" : " Minute";
      if (hoursString < 0 || minutesString < 0) {
        this.numberOfHours = "";
      } else {
        this.numberOfHours =
          hoursString + hourText + minutesString + minuteText;
      }
    },
    addOtDuration() {
      const planDate = moment(this.formData.planDate, 'DD-MM-yyyy');
      this.formData.otDurations.push({
        startTime: planDate.toDate(),
        endTime: planDate.toDate(),
        breakDuration: planDate.toDate(),
      })
    },
    removeOtDuration(index) {
      this.formData.otDurations = this.formData.otDurations.filter((_, _index) => _index !== index);
    },
    async getRoster() {
      this.isLoading = true;
      const result = await getRosterDetails(this.$route.params.rosterId);
      if (!result.success) {
        this.error = result.payload;
        this.isLoading = false;
        this.$scrollTop();
        return;
      }
      this.setFormData(get(result, "payload.data", {}));
      this.isLoading = false;
    },
    async getDwsOptions() {
      if (!this.formData.lineId || !this.formData.officer) return;
      const result = await getLineDws({
        lineId: this.formData.lineId,
        officerId: this.formData.officer.value,
      });
      if (result.success) {
        this.dwsOptions = get(result, "payload.list", []).map((item) => ({
          ...item,
          label: this.getDwsLabel(item.dws, item.shiftStart, item.shiftEnd),
        }));
      }
    },
    async submit(forced = false) {
      if (this.isSubmitting) return;
      this.error = "";
      this.v$.$reset();
      this.v$.$touch();

      if (this.v$.$invalid) {
        this.error = "Please complete all required fields";
        this.$scrollTop();
      } else if (!this.isValidOTTimeRange) {
        this.error =
          "OT Time Range must be valid";
        this.$scrollTop();
      } else {
        this.isSubmitting = true;
        const result = await postPlannedOT({
          lineId: this.formData.lineId,
          stationId: this.formData.stationId,
          officerId: this.formData.officer.value,
          planDate: parseDateTime(this.formData.planDate),
          otDurations: this.formData.otDurations.map(({
            startTime, endTime, breakDuration
          }) => ({
            startTime: parseDateTime(startTime),
            endTime: parseDateTime(endTime),
            breakDuration: displayDateTime(breakDuration, 'HH:mm:ss')
          })),
          remark: this.formData.remarks,
          forced: forced,
        });
        this.isSubmitting = false;
        if (result.success) this.$router.go(-1);
        else {
          this.error = result.payload;
          const forcible = get(result, "payload.forcible", false);
          if (forcible) {
            this.showMaxOTHourModal = true;
          }
          this.$scrollTop();
        }
      }
    },
  },
};
</script>

<style scope>
.edit_roster .AsomCard__Body {
  @apply sm:rounded-tr-md sm:rounded-tl-md;
}
</style>
